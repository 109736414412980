import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ classes }) => (
  <svg
    className={classes}
    width="28"
    height="41"
    viewBox="0 0 28 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 13.6667C28 20.7412 23.6075 27.1459 15.6459 38.6257C14.8496 39.7738 13.1504 39.7738 12.3541 38.6257C4.3925 27.1459 0 20.7412 0 13.6667C0 6.11878 6.26801 0 14 0C21.732 0 28 6.11878 28 13.6667Z"
      fill="#0284C7"
    />
    <circle cx="14" cy="14" r="12" fill="#075985" />
    <path
      d="M14.7071 6.29289C14.3166 5.90237 13.6834 5.90237 13.2929 6.29289L6.29289 13.2929C5.90237 13.6834 5.90237 14.3166 6.29289 14.7071C6.68342 15.0976 7.31658 15.0976 7.70711 14.7071L8 14.4142V21C8 21.5523 8.44772 22 9 22H11C11.5523 22 12 21.5523 12 21V19C12 18.4477 12.4477 18 13 18H15C15.5523 18 16 18.4477 16 19V21C16 21.5523 16.4477 22 17 22H19C19.5523 22 20 21.5523 20 21V14.4142L20.2929 14.7071C20.6834 15.0976 21.3166 15.0976 21.7071 14.7071C22.0976 14.3166 22.0976 13.6834 21.7071 13.2929L14.7071 6.29289Z"
      fill="#BAE6FD"
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: theme.colors.Grey400,
};

export default Svg;
