import { api } from '.';

export const readPaymentGateways = async (headers, params) =>
  api.get('payment-gateways/', { headers, params });

export const gatewayAuthorize = async (gatewayName, headers, params) =>
  api.get(`${gatewayName}-gateways/authorize/`, { headers, params });

export const deleteGateway = async (gatewayName, id) =>
  api.delete(`${gatewayName}-gateways/${id}/`);

export const patchGateway = async (gatewayName, id, data) =>
  api.patch(`${gatewayName}-gateways/${id}/`, data);

export const readSquareLocations = async (id) => api.get(`square-gateways/${id}/location/`);

export const readSquareGateway = async (id) => api.get(`square-gateways/${id}/`);

export const readStripeGateway = async (id) => api.get(`stripe-gateways/${id}/`);

export const readLocalPayGateway = async () => api.get('local-pay-gateways/');

export const createLocalPayGateway = async () => api.post('local-pay-gateways/');

export const patchLocalPayGateway = async (params) => api.patch('local-pay-gateways/', params);

export const readPayrixGateway = async () => api.get('payrix-gateways/');

export const patchPayrixGateway = async (params) => api.patch('payrix-gateways/', params);

export const readPayrixGatewayCustomers = async (params) =>
  api.get('payrix-gateway-customers', { params });

export const createTerminal = async (id, data) =>
  api.post(`payrix-gateways/${id}/terminals/`, data);

export const readTerminals = async (gateway, id) => api.get(`${gateway}-gateways/${id}/terminals/`);

export const deleteTerminal = async (gateway, gatewayId, id) =>
  api.delete(`${gateway}-gateways/${gatewayId}/terminals/${id}/`);

export const updateTerminal = async (gateway, gatewayId, id, data) =>
  api.patch(`${gateway}-gateways/${gatewayId}/terminals/${id}/`, data);

export const readSellerPayrixGatewayCustomers = async (params) =>
  api.get('seller-payrix-gateway-customers/', { params });

export const readPayrixPublicKey = async () => api.get('payrix-public-key/');

export const readPayrixTermsAndConditions = async () => api.get('payrix-terms-and-conditions/');

export const patchPayrixTermsAndConditions = async (data) =>
  api.patch('payrix-terms-and-conditions/', data);
