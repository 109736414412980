import { SpinnerSmall } from 'assets/svgIcons/index';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { patchPayrixTermsAndConditions } from 'api/backoffice/payment-gateways';
import useApi from 'hooks/common/api';
import Button from 'components/styled/Button';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio/InputCheckboxRadio';
import ModalPortal from 'components/styled/ModalPortal';
import './PayrixTermsAndConditionsModal.css';
import { accountAtom } from 'state/backoffice/userState';
import { COUNTRIES } from 'helpers/constants';
import { useRecoilValue } from 'recoil';

const BANK_DISCLOSURE = 'bank-disclosure';
const MERCHANT_TERMS = 'merchant-terms';

const PayrixTermsAndConditionsModal = ({ closeModal }) => {
  const { t } = useTranslation();
  const [isBankDisclosureChecked, setIsBankDisclosureChecked] = useState(false);
  const [isMerchantTermsChecked, setIsMerchantTermsChecked] = useState(false);
  const allOptionsChecked = isBankDisclosureChecked && isMerchantTermsChecked;

  const account = useRecoilValue(accountAtom);
  const isCanada = account?.country_code === COUNTRIES.CA

  const { request: patchTermsRequest, loading: loadingPatch } = useApi(
    patchPayrixTermsAndConditions
  );

  const confirmTerms = async () => {
    const acceptanceDateTime = new Date().toISOString();
    const resp = await patchTermsRequest({
      bank_disclosure_acceptance: acceptanceDateTime,
      merchant_terms_conditions: acceptanceDateTime,
    });

    if (resp) closeModal();
  };

  const handleClick = () => {
    if (!allOptionsChecked) {
      setIsBankDisclosureChecked(true);
      setIsMerchantTermsChecked(true);
      return;
    }

    confirmTerms();
    closeModal();
  };

  const getAcceptButtonLabel = () => {
    return loadingPatch ? <SpinnerSmall /> : allOptionsChecked ? t('Confirm') : t('Accept All');
  };

  return (
    <ModalPortal
      target="backoffice-payrix-terms-and-conditions-modal"
      title={t('terms-and-conditions/modal/update-credit-payment-provider--title')}
      description={t('terms-and-conditions/modal/update-credit-payment-provider--description')}
      onClose={closeModal}
      classes="overflow-y-visible">
      <div>
        <div className="flex flex-col px-6 pb-4 space-y-2 modal-container">
          <InputCheckboxRadio
            id={BANK_DISCLOSURE}
            name={BANK_DISCLOSURE}
            checked={isBankDisclosureChecked}
            onChange={() => setIsBankDisclosureChecked(!isBankDisclosureChecked)}
            label={t('terms-and-conditions/modal/payrix-bank-disclosure--title')}
            help={
              <div className="flex flex-col">
                <span>{t('terms-and-conditions/modal/read-agreed-consented')}</span>
                <a
                  className="text-green-600 text-sm underline"
                  href={isCanada ? t('terms-and-conditions/modal/payrix-bank-disclosure-page/link-CA') : t('terms-and-conditions/modal/payrix-bank-disclosure-page/link')}
                  target="_blank"
                  rel="noreferrer">
                  {t('terms-and-conditions/modal/payrix-bank-disclosure-page/link--label')}
                </a>
              </div>
            }
          />
          <InputCheckboxRadio
            id={MERCHANT_TERMS}
            name={MERCHANT_TERMS}
            checked={isMerchantTermsChecked}
            onChange={() => setIsMerchantTermsChecked(!isMerchantTermsChecked)}
            label={t('terms-and-conditions/modal/payrix-bank-merchant-terms--title')}
            help={
              <div className="flex flex-col">
                <span>{t('terms-and-conditions/modal/read-agreed-consented')}</span>
                <a
                  className="text-green-600 text-sm underline"
                  target="_blank"
                  rel="noreferrer"
                  href={isCanada ? t('terms-and-conditions/modal/payrix-bank-merchant-terms/link-CA') : t('terms-and-conditions/modal/payrix-bank-merchant-terms/link')}>
                  {t('terms-and-conditions/modal/payrix-bank-merchant-terms/link--label')}
                </a>
              </div>
            }
          />
        </div>
        <div className="modal--actions justify-between">
          <Button classes="button button--white" onClick={closeModal}>
            {t('Close')}
          </Button>
          <Button classes="button" onClick={handleClick}>
            {getAcceptButtonLabel()}
          </Button>
        </div>
      </div>
    </ModalPortal>
  );
};

PayrixTermsAndConditionsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default PayrixTermsAndConditionsModal;
