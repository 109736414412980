import { ArrowRight, ExclamationTriangleFilled } from 'assets/svgIcons/index';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import { readPayrixTermsAndConditions } from 'api/backoffice/payment-gateways';
import useApi from 'hooks/common/api';
import PayrixTermsAndConditionsModal from './PayrixTermsAndConditionsModal';

const PayrixTermsAndConditionsAlert = () => {
  const { t } = useTranslation();
  const { Yellow400, Yellow800 } = theme.colors;
  const [showModal, setShowModal] = useState(false);

  const [payrixTerms, setPayrixTerms] = useState(null);
  const { request: readTermsRequest } = useApi(readPayrixTermsAndConditions);

  const fetchAlerts = async () => {
    const data = await readTermsRequest();
    setPayrixTerms(data);
  };

  useEffect(() => {
    fetchAlerts();
  }, []);

  if (!payrixTerms?.required || !!payrixTerms?.accepted_all_terms) return null;

  return (
    <div className="flex w-full z-modal sticky top-0 bg-yellow-50 h-10 items-center px-4 border border-yellow-400">
      <div className="flex justify-between w-full">
        <div className="flex space-x-3 items-center">
          <ExclamationTriangleFilled fill={Yellow400} />
          <span className="text-yellow-800">
            {t('terms-and-conditions/alert/update-credit-payment-provider')}
          </span>
        </div>
        <div
          className="flex space-x-1 cursor-pointer items-center"
          onClick={() => setShowModal(true)}>
          <span className="flex text-yellow-800">
            {t('terms-and-conditions/alert/accept-terms-and-conditions')}
          </span>
          <ArrowRight stroke={Yellow800} />
        </div>
      </div>
      {showModal && (
        <PayrixTermsAndConditionsModal
          closeModal={() => {
            setShowModal(false);
            fetchAlerts();
          }}
        />
      )}
    </div>
  );
};

export default PayrixTermsAndConditionsAlert;
