import { SpinnerSmall, X } from 'assets/svgIcons';
import ExclamationCircleFilled from 'assets/svgIcons/ExclamationCircleFilled';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import theme from 'styles/theme';
import './InputText.css';

const InputText = ({
  classes,
  onChange,
  id,
  name,
  inputClasses,
  labelClasses,
  hintClasses,
  containerClasses,
  value,
  labelText, // Text or component or text + component
  placeholder,
  IconLeft, // React component, pass SVG
  IconRight,
  error,
  hintText, // Text or component or text + component
  disabled,
  type,
  onBlur,
  onFocus,
  onClick,
  greaterThanZero,
  SubmitButton,
  onKeyDown,
  onKeyUp,
  multiLine,
  canResize,
  pattern,
  required,
  autoFocus,
  maxLength,
  isClearable,
  onClear,
  loading,
  maxDecimals,
}) => {
  const inputField = useRef(null);
  const PHONE_REGEX = /^[0-9\+\ \.\-\(\)]*$/;

  // MAX_DECIMALS_REGEX allows how many integer characters you want, but only [maxDecimals] decimals
  const MAX_DECIMALS_REGEX = new RegExp(
    `^\\d{0,}\\d{0,${maxDecimals}}(\\.\\d{0,${maxDecimals}})?$`
  );

  const onInputChange = (e) => {
    if (type == 'tel' && e.target.value.match(PHONE_REGEX) == null) return;
    if (maxDecimals && e.target.value.match(MAX_DECIMALS_REGEX) == null) return;
    onChange(e);
  };

  const onWheel = (e) => {
    inputField.current.blur(); // blur when user scrolls
  };

  const clearInput = () => {
    onInputChange({ target: { name, value: '' } });
    onClear();
  };

  return (
    <div
      className={`w-full input-text--container ${
        disabled ? 'input-text--container--disabled' : ''
      } ${classes}`}>
      <label htmlFor={id} className={`input-text--label ${labelClasses}`}>
        {labelText}
        <div
          className={`input-text--icon__container ${labelText ? 'mt-1' : ''} ${containerClasses}`}>
          {IconLeft && (
            <div className="input-text--icon">
              <IconLeft />
            </div>
          )}
          {multiLine && multiLine > 0 ? (
            <textarea
              id={id}
              name={name}
              ref={inputField}
              className={`input-text--input ${inputClasses} ${
                IconRight ? 'input-text--input__iconRight' : ''
              } ${error ? 'input-text--input__error' : ''} ${
                greaterThanZero ? 'input-text--input__gtzero' : ''
              } ${canResize ? 'input-text--input--canResize' : ''}`}
              onChange={(e) => onInputChange(e)}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={(e) => onBlur(e)}
              onFocus={(e) => onFocus(e)}
              onClick={(e) => onClick(e)}
              onKeyDown={(e) => onKeyDown(e)}
              onKeyUp={(e) => onKeyUp(e)}
              value={value}
              pattern={pattern}
              required={required}
              autoFocus={autoFocus}
              maxLength={maxLength}
            />
          ) : (
            <input
              id={id}
              name={name}
              ref={inputField}
              className={`input-text--input ${inputClasses} ${
                IconRight ? 'input-text--input__iconRight' : ''
              } ${error ? 'input-text--input__error' : ''} ${
                greaterThanZero ? 'input-text--input__gtzero' : ''
              }`}
              onChange={onInputChange}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              type={type}
              onBlur={(e) => onBlur(e)}
              onFocus={(e) => onFocus(e)}
              onClick={(e) => onClick(e)}
              onKeyDown={(e) => onKeyDown(e)}
              onKeyUp={(e) => onKeyUp(e)}
              onWheel={(e) => onWheel(e)}
              pattern={pattern}
              required={required}
              autoFocus={autoFocus}
              maxLength={maxLength}
            />
          )}
          {IconRight && !error && (
            <div className="input-text--icon input-text--icon__right">
              <IconRight />
            </div>
          )}
          {error && (
            <div className="input-text--icon input-text--icon__right">
              <ExclamationCircleFilled stroke={theme.colors.Red500} />
            </div>
          )}
          {loading && (
            <div className="input-text--icon input-text--icon__right">
              <SpinnerSmall />
            </div>
          )}
          {isClearable && value && (
            <div
              className={`input-text--icon input-text--icon__right ${
                SubmitButton ? 'mr-10' : ''
              } ${containerClasses}`}
              onClick={clearInput}>
              <X />
            </div>
          )}
          {SubmitButton && SubmitButton}
        </div>
      </label>
      {error ? <p className="input-text--error">{error}</p> : null}
      {hintText ? <p className={`input-text--hint ${hintClasses}`}>{hintText}</p> : null}
    </div>
  );
};

InputText.propTypes = {
  classes: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  hintClasses: PropTypes.string,
  containerClasses: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  IconLeft: PropTypes.elementType,
  IconRight: PropTypes.elementType,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  greaterThanZero: PropTypes.bool,
  SubmitButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  multiLine: PropTypes.number, // if multiLine > 0 it's a textarea, not input
  canResize: PropTypes.bool, // can resize a textarea
  pattern: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func,
  loading: PropTypes.bool,
};

InputText.defaultProps = {
  classes: '',
  value: '',
  onChange: () => {},
  name: '',
  id: '',
  inputClasses: '',
  labelClasses: '',
  hintClasses: '',
  containerClasses: '',
  labelText: '',
  placeholder: '',
  IconLeft: null,
  IconRight: null,
  error: '',
  hintText: '',
  disabled: false,
  type: 'text',
  onBlur: () => {},
  onFocus: () => {},
  onClick: () => {},
  greaterThanZero: false,
  SubmitButton: undefined,
  onKeyDown: () => {},
  onKeyUp: () => {},
  multiLine: 0,
  canResize: false,
  pattern: '',
  required: false,
  autoFocus: false,
  maxLength: undefined,
  isClearable: false,
  onClear: () => {},
  loading: false,
};

export default InputText;
