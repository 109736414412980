import { SpinnerSmall } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import './InputCheckboxRadio.css';

const InputCheckboxRadio = ({
  checked,
  onChange,
  name,
  id,
  labelClasses,
  inputClasses,
  internalLabelClasses,
  label,
  help,
  error,
  disabled,
  type,
  loading,
}) => (
  <label
    htmlFor={id}
    className={`input-cr--label ${labelClasses} ${loading ? 'input-cr--label--loading' : ''} ${
      disabled ? 'disabled' : ''
    }`}>
    {loading ? (
      <span className="input-cr--loading">
        <SpinnerSmall />
      </span>
    ) : (
      <input
        id={id}
        name={name}
        className={`input-cr--input ${inputClasses} ${error ? 'input-cr--input__error' : ''}`}
        onChange={(e) => onChange(e)}
        checked={checked}
        disabled={disabled}
        type={type}
      />
    )}
    <div className="input-cr--info">
      {label ? <h4 className={internalLabelClasses}>{label}</h4> : ''}
      {error ? <p className="input-text--error">{error}</p> : ''}
      {help ? <p>{help}</p> : ''}
    </div>
  </label>
);

InputCheckboxRadio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  internalLabelClasses: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  help: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

InputCheckboxRadio.defaultProps = {
  checked: false,
  onChange: () => {},
  labelClasses: '',
  inputClasses: '',
  internalLabelClasses: '',
  label: '',
  help: '',
  disabled: false,
  error: '',
  type: 'checkbox',
  loading: false,
};

export default InputCheckboxRadio;
